import { Dialog, Stack } from "@mui/material"
import {
  OTPModalContent,
  OTPModalInput,
  OTPModalTitle,
  OTPModalSubtite,
  CloseIconButton,
  InputWrapper,
  ButtonWrapper,
} from "./styles"
import OTPMobileIcon from "../../assets/OTPMobileIcon.svg"
import { useEffect, useRef, useState } from "react"
import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/dist/query"
import { GetOTPParams, UserLoginParams } from "../../apis/userApi"
import { Button } from "../Button"
import CloseIcon from "@mui/icons-material/Close"
import { User } from "../../models/users.model"

type OTPModalProps = {
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  mobileNumber: string
  onConfirm: MutationTrigger<
    MutationDefinition<
      UserLoginParams,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        object,
        FetchBaseQueryMeta
      >,
      "centers",
      User,
      "/api"
    >
  >
  resendOTP: MutationTrigger<
    MutationDefinition<
      GetOTPParams,
      BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        object,
        FetchBaseQueryMeta
      >,
      "centers",
      any,
      "/api"
    >
  >
}

const OTPModal = ({
  open,
  onClose,
  onConfirm,
  mobileNumber,
  resendOTP,
}: OTPModalProps) => {
  const [inputValues, setInputValues] = useState<string[]>(["", "", "", ""])
  const inputRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    return () => {
      //  reseet state on unmount
      setInputValues(["", "", "", ""])
    }
  }, [])

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRefs.current[0]])

  const handleInputChange = (index: number, value: string) => {
    // Check if the value is a single digit between 0-9
    const sanitizedValue = value.replace(/\D/g, "").substr(0, 1)
    setInputValues((prevValues) => {
      const newValues = [...prevValues]
      newValues[index] = sanitizedValue
      return newValues
    })

    // Focus on the next input if the value is entered
    if (sanitizedValue !== "" && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === "Enter") {
      if (index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1].focus()
      } else {
        event.preventDefault()
        handleConfirm()
      }
    } else if (
      (event.key === "Backspace" || event.key === "Delete") &&
      index > 0
    ) {
      event.preventDefault()
      const newValues = [...inputValues]
      newValues[index] = ""
      setInputValues(newValues)
      inputRefs.current[index - 1].focus()
    }
  }

  const handleConfirm = () => {
    if (inputValues.filter((value) => value === "").length === 0) {
      onConfirm({ mobileNumber, OTP: inputValues.join("") })
      onClose(false)
    }
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="otp-dialog-title"
      aria-describedby="otp-dialog-description"
      onClose={onClose}
    >
      <CloseIconButton onClick={() => onClose(false)}>
        <CloseIcon />
      </CloseIconButton>
      <OTPModalContent>
        <img src={OTPMobileIcon} height="48px" alt="" />
        <OTPModalTitle>
          Please check the OTP received on your mobile
        </OTPModalTitle>
        <OTPModalSubtite>
          We've sent the code to <span>{mobileNumber}</span>
        </OTPModalSubtite>
        <Stack direction="column" mt="20px">
          <InputWrapper>
            {inputValues.map((value, index) => (
              <OTPModalInput
                key={index}
                type="text"
                inputMode="numeric"
                maxLength={1}
                value={value}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(input) => {
                  if (input) {
                    inputRefs.current[index] = input
                  }
                }}
              />
            ))}
          </InputWrapper>
          <OTPModalSubtite sx={{ textAlign: "left" }}>
            Didn’t receive a code?{" "}
            <span
              className="resend"
              onClick={() => resendOTP({ mobileNumber })}
            >
              Click to resend
            </span>
          </OTPModalSubtite>
        </Stack>
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm}>
            Confirm
          </Button>
        </ButtonWrapper>
      </OTPModalContent>
    </Dialog>
  )
}

export default OTPModal
