import { configureStore } from "@reduxjs/toolkit"
import { isRejectedWithValue } from "@reduxjs/toolkit"
import BookingReducer from "../slices/bookingSlice"
import { centersApi } from "../apis/centersApi"
import UserReducer from "../slices/userSlice"
import AppReducer, { showDialog, showToast } from "../slices/appSlice"
import { APP_MODULE, BOOKING_MODULE, USER_MODULE } from "../constants/modules"

export const rtkQueryErrorLogger =
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_api: any) => (next: (action: any) => void) => (action: any) => {
    if (isRejectedWithValue(action)) {
      //  show dialog box to update the application version
      if (action?.payload?.data?.error_code === 426) {
        store.dispatch(showDialog())
      }

      store.dispatch(
        showToast({
          toastType: "error",
          msg: action?.payload?.data?.data?.message || "Something went wrong",
        }),
      )
    }
    return next(action)
  }

export const store = configureStore({
  reducer: {
    [APP_MODULE]: AppReducer,
    [BOOKING_MODULE]: BookingReducer,
    [USER_MODULE]: UserReducer,
    [centersApi.reducerPath]: centersApi.reducer,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware()
      .concat(centersApi.middleware)
      .concat(rtkQueryErrorLogger),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
