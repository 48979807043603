export class User {
  accessToken = ""
  userId = ""
  mobile = ""
  firstName = ""
  LastName = ""

  constructor(user?: any) {
    if (user) {
      this.accessToken = user?.access_token
      this.userId = user?.user?.id
      this.mobile = user?.user?.mobile
      this.firstName = user?.user?.first_name
      this.LastName = user?.user?.last_name
    }
  }
}

export class UserContact {
  userId = ""
  mobile = ""
  nickname = ""

  constructor(contacts?: any) {
    if (contacts) {
      this.userId = contacts?.id
      this.mobile = contacts?.mobile
      this.nickname = contacts?.nickname
    }
  }
}
