export class PaymentOrderDetails {
  amount = 0
  currency = ""
  id = ""
  mobile = ""
  firstName = ""
  LastName = ""
  email = ""
  bookingCodes: string[] = []

  constructor(data?: any) {
    if (data) {
      this.amount = data?.payment_order_details?.amount
      this.currency = data?.payment_order_details?.currency
      this.id = data?.payment_order_details?.id
      this.mobile = data?.user_details?.mobile
      this.firstName = data?.user_details?.first_name
      this.LastName = data?.user_details?.last_name
      this.bookingCodes = data?.day_pass_bookings?.map(
        (data: { booking_code: string }) => data.booking_code,
      )
    }
  }
}
